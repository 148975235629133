import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {JiraTask} from './jira-task';
import {JiraTasksService} from './jira-tasks.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ToasterService} from 'angular2-toaster';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import 'rxjs-compat/add/observable/forkJoin';
declare var $: any;

@Component({
  selector: 'app-jira-task',
  templateUrl: './jira-task.component.html',
  styleUrls: ['./jira-task.component.css']
})
export class JiraTaskComponent implements OnInit {

  jiraTask: JiraTask;
  files: File[];
  issueTypes: string[];
  classifications: string[];
  businessAreas: string[];
  jiraTaskURl: string;
  cellNumberForDisplay: string;
  countryCode = '+27-';


  @ViewChild('itemForm', {static: false})
  itemForm: any;
  @ViewChild('filePicker', {static: true})
  filePicker: ElementRef;


  @BlockUI() blockUI: NgBlockUI;

  constructor(public jiraTasksService: JiraTasksService,
              public toasterService: ToasterService) {
  }

  ngOnInit() {
    console.log('Loaded page!!');
    this.resetForm();
    this.blockUI.start();
    this.jiraTasksService.getIssueTypes().subscribe((issueTypes: string[]) => {
      this.issueTypes = issueTypes;
      this.blockUI.stop();
    }, (error => {
      this.blockUI.stop();
      if (error.error) {
        this.toasterService.pop('error', 'Error', error.error.message);
      } else {
        this.toasterService.pop('error', 'Error', error.message);
      }
    }));

    this.jiraTasksService.getBusinessArea().subscribe((businessAreas: string[]) => {
      this.businessAreas = businessAreas;
      this.blockUI.stop();
    }, (error => {
      this.blockUI.stop();
      if (error.error) {
        this.toasterService.pop('error', 'Error', error.error.message);
      } else {
        this.toasterService.pop('error', 'Error', error.message);
      }
    }));
    this.jiraTasksService.getClassification().subscribe((classifications: string[]) => {
      this.classifications = classifications;
      this.blockUI.stop();
    }, (error => {
      this.blockUI.stop();
      if (error.error) {
        this.toasterService.pop('error', 'Error', error.error.message);
      } else {
        this.toasterService.pop('error', 'Error', error.message);
      }
    }));

  }

  attachFiles(e) {
    this.files = new Array();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
    }
  }

  save(form: NgForm) {
    const that = this;
    console.log(that);
    this.jiraTask.summary = this.jiraTask.title;
    this.jiraTask.cellNumber = this.cellNumberForDisplay;
    if (this.jiraTask.cellNumber.startsWith('0') && this.countryCode === '+27-' ) {
        this.jiraTask.cellNumber = this.jiraTask.cellNumber.replace(/^0+/, '');
    }
    this.jiraTask.cellNumber = this.countryCode +  this.jiraTask.cellNumber;
    this.blockUI.start();
    this.jiraTasksService.save(this.jiraTask).subscribe((taskId: string) => {
       if (this.files) {
         const observables: Observable<any>[] = [];
         this.files.forEach((blob: File) => {
           observables.push(this.jiraTasksService.uploadAttachment(blob, taskId));
         });

         Observable.forkJoin(observables)
           .subscribe(dataArray => {
             // All observables in `observables` array have resolved and `dataArray` is an array of result of each observable
             $(that.filePicker.nativeElement).fileinput('clear');
           });
       }
       this.toasterService.pop('success', 'Saved', 'Issue has been created -> ' + taskId );
       this.resetForm();
       // form.resetForm();
       this.jiraTaskURl = 'https://vodacom.atlassian.net/browse/' + taskId;
       this.blockUI.stop();
     }, (error => {
       this.blockUI.stop();
       if (error.error) {
         this.toasterService.pop('error', 'Error', error.error.message);
       } else {
         this.toasterService.pop('error', 'Error', error.message);
       }
     }));
  }
  // tslint:disable-next-line:variable-name
  onCountryCodeChange(any) {
    this.countryCode = any.target.value;
  }

  handleClick(remedyInd) {
    // tslint:disable-next-line:prefer-const
    const divHide = document.getElementById('remedyDiv');
    // tslint:disable-next-line:triple-equals
    if (remedyInd.target.value == 'yes') {
      // tslint:disable-next-line:no-unused-expression
      divHide.style.display = 'grid';
    } else {
      divHide.style.display  = 'none' ;
    }
  }
  getjiraTaskURl() {
    return this.jiraTaskURl;
  }
  displayJiraTaskURl() {
    return (this.jiraTaskURl == null);
  }

  resetForm() {
    this.jiraTask = new JiraTask();
    this.jiraTask.businessArea = '-1';
    this.jiraTask.classification = '-1';
    this.jiraTask.priority = '-1';
    this.files = new Array();
  }

  isFormValid() {
    // console.log('jiraTask:= ' + JSON.stringify(this.jiraTask));
    return this.itemForm && this.itemForm.valid && this.jiraTask.classification !== '-1';
  }
}
