export class JiraTask {
  title: string;
  description: string;
  userEmail: string;
  sendConfirmationMail: boolean;
  cellNumber: string;
  summary: string;
  reporter: string;
  risks: string;
  assumptions: string;
  dependencies: string;
  businessArea: string;
  priority: string;
  classification: string;
  remedyNumber: string;
}
